import * as React  from 'react';
import {
    Box,
    Divider, Typography
} from '@mui/material';
import InsightItem from './components/InsightItem';
import {useEffect, useState} from 'react';
import {InsightInt} from '../types';
import {WhiteBgPaper} from '../../../../shared/new-components/hf-styled-components/WhiteBgPaddedPapper';
import {getScoreInsightsList} from '../../../../shared/services/migrated/scoreInsightsService';
import Dates from '../../../../utilities/date.utilities';
import {getService} from '../../../../../migration_utils/react-in-angular';
import HappyLoading from '../../../../shared/components/_migrated/loader/loading.directive';
interface EngagementInsightsListProps {
    to: Date;
    dateGrouping: string;
    onInsightSelected: (insight: InsightInt | undefined) => void;
}


const EngagementKeyFindingsList = ({to, dateGrouping, onInsightSelected}: EngagementInsightsListProps) => {


    const [selectedInsight, setSelectedInsight] = useState<InsightInt | null>(null);
    const [insights, setInsights] = useState<InsightInt[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const ErrorSvrc = getService('ErrorSvrc');


    useEffect(() => {
        setLoading(true);

        getScoreInsightsList(dateGrouping, to,(err, results) => {
            setLoading(false);
            if (err) {
                ErrorSvrc.showErrorModal(err);
                return;
            }
            setInsights(results);

        });

    }, [to, dateGrouping]);

    const onInsightSelect = (insight: InsightInt) => {

        const newSelectedInsight = insight.id === selectedInsight?.id ? null : insight;
        setSelectedInsight(newSelectedInsight);
        onInsightSelected(newSelectedInsight);

    }

    return (
        <WhiteBgPaper
            sx={{
                display: 'flex',
                padding:0,
                flexDirection: 'column',
                backgroundColor: 'rgb(244, 247, 250)',
            }}
        >

            <>
                <Box
                    p={2}
                    sx={{
                        display: 'flex',
                        textAlign: 'left',
                        flexWrap: 'wrap',
                        borderTopLeftRadius: 'inherit',
                        borderTopRightRadius: 'inherit',
                        bgcolor: (theme) =>
                            theme.palette.mode === 'dark' ? '#2f3c50' : '#fff',
                    }}
                >
                    <Box sx={{ flexGrow: 1, mr: 1 }}>
                        <Typography variant="h4">
                              Where to put focus
                        </Typography>

                        <Typography variant="subtitle1">
                            Click on each insight to see in the heatmap where it is happening
                        </Typography>
                    </Box>
                </Box>
                <HappyLoading loading={loading}>

                    {insights?.map((insight, index) => (
                        <>
                            <InsightItem
                                key={index}
                                isSelected={selectedInsight?.id === insight.id}
                                insight={insight}
                                onInsightClick={(insight) => {onInsightSelect(insight)}}
                            ></InsightItem>
                            <Divider />
                        </>

                    ))}
                </HappyLoading>
            </>

        </WhiteBgPaper>
    );

}

export default EngagementKeyFindingsList;
