import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getService } from 'reactInAngular';


import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import HappyLoading from '../../../../../../shared/components/_migrated/loader/loading.directive';
import {Box} from '@mui/material';
import {
    getBenchmarkDescription,
    getQualityDescription, getVariationDescription, renderPopover
} from 'root/app/components/engagement/components/engagement_heatmap/components/cellTypes/cell_utils';
import Numbers from 'root/app/utilities/number.utilities';
import Style from 'root/app/utilities/style.utils';
import Valuation from 'root/app/utilities/valuation.utils';

const ENPSCell = ({ cellData, loading, segment, column, selectedInsight, onCellClick}) => {
    const [value, setValue] = useState(null);
    const [hasData, setHasData] = useState(true);
    const [color, setColor] = useState('');

    const $translate = getService('$translate');


    useEffect(() => {

        if (cellData) {



            const isRepresentative = cellData.quality === 'NORMAL' || cellData.quality === 'GOOD';

            setValue(Numbers.roundNumber(cellData.result, 0));
            let calculatedColor = Style.noData;
            if (cellData.result === null || cellData.result === undefined) {
                setHasData(false);
                calculatedColor = Style.noData;
                if (!isRepresentative) {
                    calculatedColor = Style.neutralNotRelevant;
                }
            } else {
                setHasData(true);
                calculatedColor = Valuation.valuationColor(cellData.valuation, true);
                if (!isRepresentative) {
                    calculatedColor = Valuation.valuationColorWithLowQuality(cellData.valuation, true);
                }
            }
            setColor(calculatedColor);
        }

    }, [cellData]);






    const handleOnCellClick = () => {
        if (onCellClick)
        {onCellClick(segment, column, selectedInsight);}
    };


    return (
        <div className="cell_heatmap" style={{ backgroundColor: color}}>
            <div className="cell_container p-new-sm">

                {!loading && (
                    <>
                        <i style={{ visibility: 'hidden' }} className="fa fa fa-info-circle text-white"></i>

                        <div className="result-container text-center small-style">
                            {hasData ? (
                                <div role="button" onClick={handleOnCellClick}>{value}</div>
                            ) : (
                                <div  >{$translate.instant('NO_DATA')}</div>
                            )}
                        </div>

                        {renderPopover(hasData, cellData, value, $translate)}
                    </>
                )}

                {loading && (
                    <Box className={'result-container'} sx={{width:'100%'}}>
                        <HappyLoading loading={loading} skeleton={2} noMessage={true}/>
                    </Box>
                )}
            </div>
        </div>
    );
};

ENPSCell.propTypes = {
    cellData: PropTypes.object.isRequired,
    segment: PropTypes.object.isRequired,
    column: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    onCellClick: PropTypes.func.isRequired,
    selectedInsight: PropTypes.object.isRequired,
};

export default ENPSCell;
