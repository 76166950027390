

import template from './manage.bulk.html';
import async from 'async';
import StringUtils from '../../../utilities/string.utilities';


import * as XLSX from 'xlsx';
import UsersBulkStepper from 'root/app/components/users/bulk/components';




function ManageUsersBulkCtrl($scope, Settings, ErrorSvrc, People, Groups, Characteristics, Languages, FileSaver, $translate, $state) {
    'ngInject';

    var _this = this;
    var hierarchyIdPattern = /\[(.*?)\]/g;

    $scope.actionSelected = false;
    $scope.templateLoaded = false;

    $scope.processingFile = false;
    $scope.action = '';

    $scope.maxEmployeesPerPage = 5;
    $scope.currentEmployeesPageView = [];

    $scope.processed = 0;
    $scope.downloadingTemplate = false;

    $scope.dateFormat = $translate.instant('DATE_FORMAT');
    if ($scope.dateFormat === 'DATE_FORMAT')
    {$scope.dateFormat = Settings.defaultDateFormat;}

    $scope.dateFormat = $scope.dateFormat.replace('DD', 'dd').replace('YYYY', 'yyyy');

    $scope.availableLanguages = [
        { id: null, label: $translate.instant('USER_INVITE_LANGUAGE_DEFAULT') }
    ];

    $scope.availableLanguages = $scope.availableLanguages.concat(Languages.appLanguages);

    $scope.genders = People.genders;

    $scope.setAction = function (action) {
        $scope.action = action;
        $scope.actionSelected =  true;
        $scope.templateLoaded = false;
        $scope.employees = [];
        $scope.resetProcessingStatus();
    };

    $scope.resetActionSelected = function () {
        $scope.actionSelected = false;
    };

    $scope.resetFileLoaded = function () {
        $scope.templateLoaded = false;
    };

    $scope.loadedProceed = function () {
        $scope.completed = true;
        $scope.processing = true;
        $scope.resetProcessingStatus();

        var bulkStream = [];

        $scope.employees.forEach(function (employee) {
            bulkStream.push(function (nextEmployee) {
                if ($scope.action === 'INVITE') {
                    People.invite(employee, function (err, invitedEmployee) {
                        if (err) {
                            employee.errorMsg = ErrorSvrc.getErrorMessage(err);
                            if (ErrorSvrc.getErrorCodeFromStatus(err) === 'DUPLICATED_EMPLOYEE_MAIL') {
                                $scope.duplicated++;
                            } else {
                                $scope.failed++;
                            }

                            employee.processed = false;
                        } else {
                            employee = invitedEmployee;
                            employee.processed = true;
                            $scope.succeed++;
                        }
                        $scope.processed++;
                        nextEmployee();
                    });
                } else if ($scope.action === 'UPDATE') {
                    People.update(employee, function (err, updatedEmployee) {
                        if (err) {
                            employee.errorMsg = ErrorSvrc.getErrorMessage(err);
                            employee.processed = false;
                            $scope.failed++;
                        } else {
                            employee = updatedEmployee;
                            employee.processed = true;
                            $scope.succeed++;
                        }
                        $scope.processed++;
                        nextEmployee();
                    });
                } else if ($scope.action === 'DELETE') {
                    var turnoverInfo = null;
                    if (employee.turnoverType || employee.turnoverDate) {
                        turnoverInfo = {};
                        turnoverInfo.turnoverType = employee.turnoverType;
                        turnoverInfo.turnoverDate = employee.turnoverDate;
                    }
                    People.delete(employee, turnoverInfo, function (err, updatedEmployee) {
                        if (err) {
                            employee.errorMsg = ErrorSvrc.getErrorMessage(err);
                            employee.processed = false;
                            $scope.failed++;
                        } else {
                            employee = updatedEmployee;
                            employee.processed = true;
                            $scope.succeed++;
                        }
                        $scope.processed++;
                        nextEmployee();
                    });
                }
            });

        });

        async.waterfall(bulkStream, function (err) {
            $scope.processing = false;
            $scope.currentEmployeesPageView = [];
            _this.page = 1;
            _this.setCurrentPageView();

        });
    };

    // Process employee file:
    $scope.processFile = function (files) {
        if (!files || files.length === 0) {return;}
        $scope.employees = [];
        $scope.currentEmployeesPageView = [];
        _this.page = 1;
        $scope.processingFile = true;
        $scope.templateLoaded = false;
        delete $scope.processingFileError;
        delete $scope.filename;
        $scope.completed = false;
        var file = files[0];
        var fileReader = new FileReader();
        fileReader.onload = function (e) {
            try {
                $scope.filename = file.name;

                var binary = '';
                var bytes = new Uint8Array(e.target.result);
                var length = bytes.byteLength;

                for (var i = 0; i < length; i++) {
                    binary += String.fromCharCode(bytes[i]);
                }

                // call 'xlsx' to read the file
                var workbook = XLSX.read(binary, { type: 'binary', cellDates: true, cellStyles: true, cellText: false,  dateNF: 'DD/MM/YYYY' });
                var employeeRows = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {
                    blankrows: false,
                    sheetRows: 1,
                    range: 1,
                    cellDates: true,
                    dateNF: 'DD/MM/YYYY'
                });

                var processingStream  = [];

                employeeRows.forEach(function (employeeRow) {

                    processingStream.push(function (nextEmployee) {
                        try {
                            // Only add the row, if it has email info
                            var employee = {
                                language: null
                            };
                            Object.keys(employeeRow).forEach(function (field) {
                                if (field.toLowerCase() === 'email') {
                                    if (StringUtils.validateEmail(employeeRow[field])) {
                                        employee.email = employeeRow[field];
                                    }
                                } else if (field.toLowerCase() === 'name') {
                                    employee.name = employeeRow[field];
                                } else if (field.toLowerCase() === 'language') {

                                    var languageCode = null;
                                    employeeRow[field].replace(/\[(.*?)\]/g, function (g0, g1) {languageCode = g1;});
                                    if (languageCode) {
                                        employee.language = languageCode;
                                    }


                                } else if (field.toLowerCase() === 'role') {
                                    employee.role = employeeRow[field] || '';
                                } else if (field.toLowerCase() === 'turnover type') {
                                    employee.turnoverType = employeeRow[field];
                                } else if (field.toLowerCase() === 'turnover date') {
                                    employee.turnoverDate = prepareSheetDate(employeeRow[field]);
                                } else if (field.toLowerCase() === 'area') {
                                    var detectedHierarchy = [];
                                    employeeRow[field].replace(/\[(.*?)\]/g, function (g0, g1) {detectedHierarchy.push(g1);});

                                    if (detectedHierarchy && detectedHierarchy.length > 0) {
                                        employee.hierarchyId = detectedHierarchy[0];
                                    }
                                } else {
                                    var found = $scope.processGroups(employee, field, employeeRow);
                                    if (!found) {
                                        $scope.processCharacteristics(employee, field, employeeRow);
                                    }
                                }
                            });

                            if ($scope.action === 'UPDATE') {
                                People.get({ email: employee.email }, function (err, result) {
                                    if (err) {
                                        employee.errorMsg = ErrorSvrc.getErrorMessage(err);
                                        employee.processed = false;
                                    } else {
                                        employee.original = result;
                                        employee.id = result.id;
                                    }
                                    nextEmployee(null, employee);
                                });
                            } else {
                                nextEmployee(null, employee);
                            }
                        } catch (err) {
                            nextEmployee(err, null);
                        }

                    });
                });

                async.parallelLimit(processingStream, 50, function (err, employees) {
                    if (employees) {
                        $scope.employees = employees;
                    }

                    _this.setCurrentPageView();
                    $scope.processingFile = false;
                    $scope.templateLoaded = true;
                    $scope.$apply();
                });

            } catch (err) {
                $scope.processingFileError = $translate.instant('BULK_EMPLOYEE_PROCESSING_FILE_PARSING_ERROR');
                $scope.processingFile = false;
                $scope.templateLoaded = false;
                $scope.$apply();
            }
        };

        fileReader.readAsArrayBuffer(file);
    };

    $scope.processGroups = function (employee, field, employeeRow) {
        if (!employee.groups) {
            employee.groups = [];
        }

        if (employeeRow[field].toLocaleString().toLowerCase() === 'x') {
            var group = Groups.getGroupByName(field);
            if (group && group !== undefined ) {
                employee.groups.push(group.id);
            }
        }
    };

    $scope.processCharacteristics = function (employee, field, employeeRow) {
        if (!employee.characteristics) {
            employee.characteristics = [];
        }

        var characteristic = _.find($scope.characteristics, function (characteristic) {
            return characteristic.description.toLowerCase() === field.toLowerCase();
        });

        if (characteristic) {
            var value;
            var date;
            if (characteristic.questionType === 'LIST') {
                value = _.find(characteristic.values, function (value) {
                    return value.value.defaultValue.toLowerCase() === employeeRow[field].toLowerCase();
                });
            } else if (characteristic.questionType === 'DATE') {

                date  = prepareSheetDate(employeeRow[field]);

            } else {
                if (characteristic.yesValue.defaultValue.toLowerCase() === employeeRow[field].toLowerCase())
                {value = { id: true };} // characteristic.yesValue;
                else
                {value = { id: false };} // characteristic.noValue;
            }

            if (value || date) {
                var newCharacteristic = {
                    characteristicId: characteristic.id,
                    questionType: characteristic.questionType
                };

                if (value) {
                    newCharacteristic.value = value.id;
                    newCharacteristic.label = Characteristics.getCharacteristicValueName(characteristic.id, value.id);
                }

                if (date) {
                    newCharacteristic.date = date;
                }

                employee.characteristics.push(newCharacteristic);
            }
        }

    };

    $scope.downloadTemplate = function () {
        $scope.downloadingTemplate = true;
        People.getTemplate($scope.action, function (err, template) {
            $scope.downloadingTemplate = false;
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                var blob = new Blob([template], {

                });

                FileSaver.saveFile(blob, 'template.xlsx');
            }

        });
    };

    _this.setCurrentPageView = function () {
        var first = (_this.page - 1) * $scope.maxEmployeesPerPage;
        var last = first + $scope.maxEmployeesPerPage;

        if ($scope.failed > 0 || $scope.duplicated > 0) {
            $scope.currentEmployeesPageView = _.filter($scope.employees, function (employee) { return employee.errorMsg; }).slice(first, last);
        } else {
            $scope.currentEmployeesPageView = $scope.employees.slice(first, last);
        }


    };

    Groups.getAll({ active: true }, function (err, groups) {
        if (err) {
            ErrorSvrc.showErrorModal(err);
            return;
        }

        $scope.groups = groups;
        Characteristics.getAll(function (err, characteristics) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                $scope.characteristics = characteristics;
            }
        });

    });

    $scope.resetProcessingStatus = function () {
        $scope.processed = 0;
        $scope.failed = 0;
        $scope.duplicated = 0;
        $scope.succeed = 0;
    };

    $scope.restart = function () {
        delete $scope.action;
        $scope.actionSelected =  false;
        $scope.templateLoaded = false;
        $scope.processingFile =  false;
        $scope.employees = [];
        $scope.completed = false;
        $scope.resetProcessingStatus();
    };

    $scope.opened = {};
    $scope.open = function ($event, elementOpened) {
        $event.preventDefault();
        $event.stopPropagation();
        $scope.opened[elementOpened] = !$scope.opened[elementOpened];
    };

    $scope.processedEmployeesFilter = function (employee) {
        if ($scope.failed > 0 || $scope.duplicated > 0) {
            // Only show the failedEmployees
            return employee.errorMsg;
        } else {
            return true;
        }
    };

    $scope.getTotalEmployees = function () {
        if (!$scope.employees) {
            return 0;
        } else if ($scope.failed > 0 || $scope.duplicated > 0) {
            return _.filter($scope.employees, function (employee) { return employee.errorMsg; }).length;
        } else {
            return $scope.employees.length;
        }
    };

    $scope.arrowBack = {
        action: function () {
            $state.go('manage.users');
        }
    };


    function prepareSheetDate(inputDate) {

        var momentDate = null;
        if (inputDate && (inputDate instanceof Date || typeof inputDate === 'date')) {
            momentDate = moment(inputDate);
        } else if (inputDate && (typeof inputDate === 'string' || inputDate instanceof String)) {
            momentDate = moment(inputDate, 'DD/MM/YYYY');
        }

        if (momentDate && momentDate.isValid()) {
            return momentDate.format(Settings.apiDateFormat)
        } else {
            console.error('Invalid date format: ' + inputDate + ' - with type ' + typeof inputDate);
            return null;
        }

    }
}


function ManageUsersBulkView($stateProvider) {
    'ngInject';

    $stateProvider.state('manage.users_bulk', {
        url: '/users/bulk',
        controller: ManageUsersBulkCtrl,
        controllerAs: '$ctrl',

        template: template,
        data: { pageTitle: 'Bulk Manage Users', roles: ['ADMIN', 'ADVISOR'] }
    });
}

export default ManageUsersBulkView;


