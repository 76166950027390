import React, { useEffect, useState } from 'react';
import {Button, Box} from '@mui/material';
import { angularize, getService } from 'reactInAngular';
import PropTypes from 'prop-types';
import {HfBannerReact} from 'root/app/shared/new-components/hf-banner/hfBanner';
import moment from 'moment/moment';

const CommentInappropriateBox = ({ comment, replyOf, inappropriateCallback, selfHandle, inlineStyle }) => {
    const [reasonText, setReasonText] = useState('');
    const [refereedAuthor, setRefereedAuthor] = useState('');
    const $translate = getService('$translate');
    const Comments = getService('Comments');
    const CommentInappropriateModal = getService('CommentInappropriateModal');
    const People = getService('People');

    const toaster = getService('toaster');

    const parseInappropriateFields = () => {
        let text = '';
        if (comment.refereed) {
            switch (comment.inappropriateReasonType) {
                case 'DISRESPECTFUL':
                    text = $translate.instant('LACK_OF_RESPECT');
                    break;
                case 'OFFENSIVE':
                    text = $translate.instant('OFFENSIVE_LANGUAGE');
                    break;
                case 'OTHER':
                    text = $translate.instant('INAPPROPRIATE_OTHER');
                    break;
                case 'THRESHOLD':
                    text = $translate.instant('INAPPROPRIATE_EMPLOYEES');
                    break;
                default:
                    text = comment.inappropriateReason || '';
                    break;
            }
        } else {
            text = $translate.instant('INAPPROPRIATE_RESPECT');
        }
        setReasonText(text);
    };

    const showInappropriate = () => {
        CommentInappropriateModal.showModal(comment, replyOf, (params) => {
            if (selfHandle && params.hasChange) {
                const commentId = params.isReplyOf ? params.isReplyOf : params.commentId;
                const replyId = params.isReplyOf ? params.commentId : null;
                Comments.inappropiate(commentId, replyId, params, (err, updatedComment) => {
                    if (err) {
                        toaster.pop('error', null, $translate.instant('ERROR_OCCURRED'));
                        inappropriateCallback && inappropriateCallback(err, null);
                    } else {
                        toaster.pop('success', null, $translate.instant('ACTION_SUCCESSFUL'));
                        inappropriateCallback && inappropriateCallback(null, updatedComment);
                    }
                });
            } else {
                inappropriateCallback && inappropriateCallback(null, comment);
            }
        });
    };




    useEffect(() => {
        parseInappropriateFields();

        if (comment?.refereed?.userId) {
            People.getProfile(comment.refereed.userId, function (err, person) {
                if (err) {
                    // TODO: Handle error
                    setRefereedAuthor( $translate.instant('UNKNOWN_USER_NAME'));
                } else {
                    setRefereedAuthor( person.name);
                }
            });
        }

    }, [comment]);


    if (inlineStyle) {

        return (
            <Box sx={{ textAlign: 'center'}}>
                <div className="small-style m-b-xs">{
                    comment.refereed ?
                        $translate.instant('COMMENT_AUTHOR_BLOCKED_DESCRIPTION',
                            {
                                date: moment.utc(comment.refereed.date).format('DD MMM YYYY'),
                                blocker: refereedAuthor, reason: reasonText
                            }) : reasonText
                }</div>

                <a className="m-t-sm" onClick={showInappropriate}>
                    {$translate.instant('SHOW_COMMENT')}
                </a>
            </Box>
        );

    } else {
        return (
            <HfBannerReact
                type={comment.refereed ? 'primary' : 'warning'}>
                <Box
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    alignItems="center">
                    <div className="small-style m-b-xs">{
                        comment.refereed ?
                            $translate.instant('COMMENT_AUTHOR_BLOCKED_DESCRIPTION',
                                {date: moment.utc(comment.refereed.date).format('DD MMM YYYY'),
                                    blocker: refereedAuthor, reason: reasonText}) : reasonText
                    }</div>
                    <Button onClick={showInappropriate} size="small">
                        {$translate.instant('SHOW_COMMENT')}
                    </Button>
                </Box>


            </HfBannerReact>
        );
    }


};

CommentInappropriateBox.propTypes = {
    comment: PropTypes.object.isRequired,
    replyOf: PropTypes.string,
    inappropriateCallback: PropTypes.func,
    selfHandle: PropTypes.bool,
    inlineStyle: PropTypes.bool
};

angularize(CommentInappropriateBox, 'hfCommentInappropriateBox2', angular.module('happyForceApp'), {
    comment: '=',
    replyOf: '=',
    inappropriateCallback: '=',
    selfHandle: '=',
    inlineStyle: '='
});

export default CommentInappropriateBox;
